.VideoSessions {
    width: 100%;
    margin: auto;
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin: 0;
    padding-right: auto;
    text-align: right;
    position: relative;
    float: right;
    top: 100px;
    font-size: small;
}