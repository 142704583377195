.PartnerReg {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: table;
    width: 100%;
    float: left;
    border-style: solid;
    border-width: 1px;
    border-color: #649132;
    border-radius: 10px;
}

.PartnerReg * {
    box-sizing: border-box;
}

.PartnerReg p {
    font-size: 1.1em;
    color: #649132;
    text-align:center;
    line-height: 3em;
}


.PartnerRegCell {
    width: 100%;
}
