.VideoSessionItem {
    font-family: 'Open Sans', sans-serif;

    font-size: 0.9em;
    text-transform: uppercase;
    background-color: rgba(245, 245, 245, 0.8);

}

.link-button {
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin: 0;
    padding-right: auto;
    text-align: right;
    position: relative;
    font-size: smaller;
}

.VideoSessionItem span {
    font-size: smaller;

}

