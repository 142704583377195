.Video {
    width: 100%;
    height: 100%;
}
.General {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: table;
    width: 100%;
    position: center;
}
