.Form {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    padding-top: 0.5em;
    display: inline-flex;
}

.Form button {
    border-radius: 0.5em;
    background: lightgray;
    border: none;
    margin-left: 0.5em;
    margin-top: 0.3em;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color: #23234b;
}

.Form button:hover {
    background: #8cd719;
    cursor: pointer;
}