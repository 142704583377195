.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    height: 100%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.General {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: table;
    width: 100%;
    position: center;
}