.hg__header {
  grid-area: header;
  border-bottom-style: solid;
  border-width: 0px;
}
.hg__footer { grid-area: footer; }
.hg__main {
  grid-area: main;

}
.hg__left {
  grid-area: navigation;
  border-right-style: none;
  border-width: 1px;
}
.hg__right {
  grid-area: ads;
  border-left-style: none;
  border-width: 1px;
}

.hg {
  display: grid;
  grid-template-areas: "header header header"
  "navigation main ads"
  "footer footer footer";
  grid-template-columns: 150px 1fr 150px;
  grid-template-rows: 100px
  1fr
  30px;
  min-height: 100vh;
}

@media screen and (max-width: 600px) {
  .hg {
    grid-template-areas: "header"
    "navigation"
    "main"
    "ads"
    "footer";
    grid-template-columns: 100%;
    grid-template-rows: 100px
    50px
    1fr
    50px
    30px;
  }
}