.Form {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    padding: 1em;

    border-radius: 3px;
    display: table;
    width: 100%;
    float: left;
}

.Form * {
    box-sizing: border-box;
}

.Form label {
    font-size: 0.9em;
}

.Form .audioOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.Form input, select {
    border: 1px solid #ccc;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    padding: 0.5em;
    margin: 1em;
    width: 18em;
}



.CountryDropdown {
    border: 1px solid #ccc;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    padding: 0.5em;
    margin: 1em;
    width: 18em;
}



.Form input[type="email"],
.Form input[type="password"] {
}

.Form button {
    padding: 0.7em;
    border-radius: 0.5em;
    background: lightgray;
    border: none;
    margin-left: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color: #23234b;
}

.Form button:hover {
    background: #8cd719;
    cursor: pointer;
}

