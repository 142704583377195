.Header {
    font-family: 'Open Sans', sans-serif;
    color: #649132;
}

.Header a
{
    text-decoration: none;
    font: 14px "Open Sans", sans-serif;
    color:#649132;
    text-transform: uppercase;
    padding-right: 1em;
}

