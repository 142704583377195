body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.FormLogout_Form__2yJ8_ {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    padding-top: 0.5em;
    display: inline-flex;
}

.FormLogout_Form__2yJ8_ button {
    border-radius: 0.5em;
    background: lightgray;
    border: none;
    margin-left: 0.5em;
    margin-top: 0.3em;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color: #23234b;
}

.FormLogout_Form__2yJ8_ button:hover {
    background: #8cd719;
    cursor: pointer;
}
.HeaderV2_Header__2w0g5 {
    font-family: 'Open Sans', sans-serif;
    color: #649132;
}

.HeaderV2_Header__2w0g5 a
{
    text-decoration: none;
    font: 14px "Open Sans", sans-serif;
    color:#649132;
    text-transform: uppercase;
    padding-right: 1em;
}


.FormReg_Form__3ywFt {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    padding: 1em;

    border-radius: 3px;
    display: table;
    width: 100%;
    float: left;
}

.FormReg_Form__3ywFt * {
    box-sizing: border-box;
}

.FormReg_Form__3ywFt label {
    font-size: 0.9em;
}

.FormReg_Form__3ywFt .FormReg_audioOnly__V-xiw {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.FormReg_Form__3ywFt input, select {
    border: 1px solid #ccc;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    padding: 0.5em;
    margin: 1em;
    width: 18em;
}



.FormReg_CountryDropdown__2nNbz {
    border: 1px solid #ccc;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    padding: 0.5em;
    margin: 1em;
    width: 18em;
}



.FormReg_Form__3ywFt input[type="email"],
.FormReg_Form__3ywFt input[type="password"] {
}

.FormReg_Form__3ywFt button {
    padding: 0.7em;
    border-radius: 0.5em;
    background: lightgray;
    border: none;
    margin-left: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color: #23234b;
}

.FormReg_Form__3ywFt button:hover {
    background: #8cd719;
    cursor: pointer;
}


.SuccessReg_SuccesReg__3ER9M {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    color: #649132;;
    position: center;
}
.PartnerReg_PartnerReg__eMN1B {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: table;
    width: 100%;
    float: left;
    border-style: solid;
    border-width: 1px;
    border-color: #649132;
    border-radius: 10px;
}

.PartnerReg_PartnerReg__eMN1B * {
    box-sizing: border-box;
}

.PartnerReg_PartnerReg__eMN1B p {
    font-size: 1.1em;
    color: #649132;
    text-align:center;
    line-height: 3em;
}


.PartnerReg_PartnerRegCell__3L7fd {
    width: 100%;
}

.Spinner_Outer__3QudC {
    display: table-row;
    width: 100px;
    margin: auto;
}
.Spinner_Spinner__1Pmk2,
.Spinner_Spinner__1Pmk2:before,
.Spinner_Spinner__1Pmk2:after {
    background: #8cd719;
    -webkit-animation: Spinner_load1__70VEg 1s infinite ease-in-out;
    animation: Spinner_load1__70VEg 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.Spinner_Spinner__1Pmk2 {
    color: #8cd719;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    margin: auto;
}
.Spinner_Spinner__1Pmk2:before,
.Spinner_Spinner__1Pmk2:after {
    position: absolute;
    top: 0;
    content: '';
}
.Spinner_Spinner__1Pmk2:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.Spinner_Spinner__1Pmk2:after {
    left: 1.5em;
}
@-webkit-keyframes Spinner_load1__70VEg {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
@keyframes Spinner_load1__70VEg {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
.VideoSessionItem_VideoSessionItem__3yTN7 {
    font-family: 'Open Sans', sans-serif;

    font-size: 0.9em;
    text-transform: uppercase;
    background-color: rgba(245, 245, 245, 0.8);

}

.VideoSessionItem_link-button__39fSe {
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin: 0;
    padding-right: auto;
    text-align: right;
    position: relative;
    font-size: smaller;
}

.VideoSessionItem_VideoSessionItem__3yTN7 span {
    font-size: smaller;

}


.VideoSessions_VideoSessions__6iOJ3 {
    width: 100%;
    margin: auto;
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
}

.VideoSessions_link-button__23N82 {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin: 0;
    padding-right: auto;
    text-align: right;
    position: relative;
    float: right;
    top: 100px;
    font-size: small;
}
.videoElement_player-wrapper__1IQ9U {
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    height: 100%;
}

.videoElement_react-player__2v19n {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.videoElement_General__1GTwq {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: table;
    width: 100%;
    position: center;
}
.VideoScreen_Video__2vjkc {
    width: 100%;
    height: 100%;
}
.VideoScreen_General__EqZ2r {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: table;
    width: 100%;
    position: center;
}

.VideoSessionToJoin_General__1LnMm {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    display: table;
    width: 100%;
    position: center;
}
.MainFilter_MainFilter__28H6Q {
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    font-size: 0.9em;
    text-transform: uppercase;
    background-color: rgba(245, 245, 245, 0.7);
}



.App_hg__header__1Rq_a {
  grid-area: header;
  border-bottom-style: solid;
  border-width: 0px;
}
.App_hg__footer__dSrgR { grid-area: footer; }
.App_hg__main__CayIL {
  grid-area: main;

}
.App_hg__left__2Cato {
  grid-area: navigation;
  border-right-style: none;
  border-width: 1px;
}
.App_hg__right__1RiNH {
  grid-area: ads;
  border-left-style: none;
  border-width: 1px;
}

.App_hg__3uU7S {
  display: grid;
  grid-template-areas: "header header header"
  "navigation main ads"
  "footer footer footer";
  grid-template-columns: 150px 1fr 150px;
  grid-template-rows: 100px
  1fr
  30px;
  min-height: 100vh;
}

@media screen and (max-width: 600px) {
  .App_hg__3uU7S {
    grid-template-areas: "header"
    "navigation"
    "main"
    "ads"
    "footer";
    grid-template-columns: 100%;
    grid-template-rows: 100px
    50px
    1fr
    50px
    30px;
  }
}
